import React from "react";
import ReactDOM from "react-dom";
import "@fas/react-scripts-ts";
import App from "./App";
import "./index.css";

function prepare(): Promise<void> {
  if (process.env.NODE_ENV !== "production" && process.env.REACT_APP_DEV_SW) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require,@typescript-eslint/no-unsafe-assignment
    const { mockApi } = require("./mocks");// we have to use require instead of import because we don't want to add mocks to the build
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    return mockApi.makeServer();
  }
  return Promise.resolve();
}

prepare().then(() => {
  ReactDOM.render(
    <App/>,
    document.getElementById("root")
  );
}).catch(() => "");
