import type {FunctionComponent} from "react";
import {menu} from "../../modules/Menu/Menu";
import {MenuCard} from "../../components/MenuCard/MenuCard";

const SubrateMainPage: FunctionComponent = () => {
  const menuLinks = menu.filter(({ text }) => text === "Subrate section");
  return (
    <>
      {menuLinks.map(({ text, links }) => (
        <MenuCard text={text}  links={links} />
      ))}
    </>
  );
};

export default SubrateMainPage;
