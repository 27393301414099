import {createLoadingSelector} from "@fas/ui-framework";
import {useSelector} from "../../store";

export const getLoading: (state: { loading: any }, k: string) => boolean = createLoadingSelector();

/**
 * Get loading
 * @example
 * const loading = useLoading("someKey");
 * @param {string} keys of loading
 * @returns {boolean} loading
 */
export function useLoading(...keys: string[]): boolean {
  return useSelector((
    state
  ): boolean => keys.some(((key): boolean => getLoading(state, key))));
}
