import environment from "environment";
import qs from "qs";
import Dayjs from "dayjs";
import requestService from "../request";
import type {Adjustment, SubrateAdjustmentTrigger} from "../../pages/SubrateAdjustmentTriggerForm/types";
import type { SubrateAdjustmentTriggerListFilters, SubrateTriggersListItem } from "../../pages/SubrateAdjustmentTriggerList";
import { formatToFront, toISOString } from "../../helpers";

type DropDownsOption = { label: string, value: string };
export type DropDownsOptions = Array<DropDownsOption>;

export function getSubratesDropdowns(params: {[k: string]: string}): Promise<DropDownsOptions> {
  return requestService
    .get<DropDownsOption>(environment.endpoints.get.getSubratesDropdowns, { params })
    .then((data: { data: { data: DropDownsOption }}) => data.data.data);
}

export function fetchAffiliates({filter}: { filter: string }) {
  return getSubratesDropdowns({
    type: "affiliate",
    field: "name",
    format: "id",
    filter,
  });
}
export function fetchAffiliatesById({filter}: { filter: string }) {
  return getSubratesDropdowns({
    type: "affiliate",
    field: "id",
    format: "id",
    filter,
  });
}

export function fetchOffers({filter}: { filter: string }) {
  return getSubratesDropdowns({
    type: "offer",
    field: "name",
    format: "id",
    filter,
  });
}

export function fetchOffersById({filter}: { filter: string }) {
  return getSubratesDropdowns({
    type: "offer",
    field: "id",
    format: "id",
    filter,
  });
}

type SaveSubrateAdjustmentTrigger = {
  affId: string,
  cpaCampaignId: string,
  country: string,
  utmContent: string | null,
  s3: string | null,
  isIncludeSubrateInfo: boolean,
  isActive: boolean,
  adjustments: Omit<Adjustment, "id">[],
}
export function saveCronusSubrateTrigger({
  id,
  affId: { value: affId } = { value: "", label: "" },
  cpaCampaignId: { value: cpaCampaignId } = { value: "", label: "" },
  country,
  isIncludeSubrateInfo,
  utmContent,
  s3,
  isActive,
  adjustments,
}: SubrateAdjustmentTrigger): Promise<void> {
  const data: SaveSubrateAdjustmentTrigger = {
    affId,
    cpaCampaignId,
    country,
    isIncludeSubrateInfo,
    utmContent: isIncludeSubrateInfo ? utmContent : null,
    s3: isIncludeSubrateInfo ? s3 : null,
    isActive,
    adjustments: adjustments?.map(({ id: _, ...item }) => ({
      ...item,
      date: toISOString(item.date),
    })),
  };
  if (id || id === 0) {
    return requestService.put<SaveSubrateAdjustmentTrigger, Promise<void>>(
      environment.endpoints.put.updateCronusSubrateTrigger.replace("{id}", String(id)),
      data
    );
  }
  return requestService.post<SaveSubrateAdjustmentTrigger, Promise<void>>(
    environment.endpoints.post.createCronusSubrateTrigger,
    data
  );
}

type GetSubrateAdjustmentTrigger = SaveSubrateAdjustmentTrigger & {
  id: number,
  offerName: string,
  affName: string,
}



export function getCronusSubrateTrigger(ruleId: string): Promise<SubrateAdjustmentTrigger> {
  return requestService
    .get<SubrateAdjustmentTrigger>(environment.endpoints.get.getCronusSubrateTrigger.replace("{id}", ruleId))
    .then(({ data: {
      id,
      affId,
      affName,
      offerName,
      cpaCampaignId,
      country,
      isIncludeSubrateInfo,
      s3,
      isActive,
      adjustments,
      utmContent,
    }}: { data: GetSubrateAdjustmentTrigger }): SubrateAdjustmentTrigger => ({
      id,
      affId: { label: affName || affId, value: affId },
      cpaCampaignId: { label: offerName || cpaCampaignId, value: cpaCampaignId },
      country,
      isIncludeSubrateInfo,
      s3: s3 || "",
      isActive,
      adjustments: adjustments.map((item, index) => ({
        ...item,
        date: formatToFront(item.date),
        id: index.toString(),
      })),
      utmContent: utmContent || "",
    }));
}

export type SubrateAdjustmentTriggerReportFilters = {
  id?: number,
  affId?: string,
  cpaCampaignI?: string,
  country?: string,
  utmContent?: string,
  s3?: string,
  status?: string,
  finishedAt?: {
    from: string,
    to: string
  }
}
export type SubrateAdjustmentTriggerReportDataRow= {
  id: number,
  affId: string,
  country: string,
  utmContent: string,
  s3: string,
  subrateOption: {
    [key: string]: string,
  },
  status: string,
  finishedAt: string,
  error: string,
}
export type SubrateAdjustmentTriggerReportParams = {
  page: number,
  limit: number,
  filters: SubrateAdjustmentTriggerReportFilters,
  sorting: { direction: string, current: string }
}
// eslint-disable-next-line max-len
export function getSubrateAdjustmentTriggerReport(params: SubrateAdjustmentTriggerReportParams): SubrateAdjustmentTriggerReportDataRow {
  const [[current, direction] = []] = Object.entries(params.sorting);
  return requestService
    .get(environment.endpoints.get.getSubrateAdjustmentTriggerReport, {
      params: {
        ...params,
        sorting: current ? { current, direction } : undefined,
      },
      paramsSerializer: (param: SubrateAdjustmentTriggerReportParams): string => qs.stringify(param, { arrayFormat: "brackets", encode: true }),
    })
    .then((data: { data: {data: SubrateAdjustmentTriggerReportDataRow, count: number }}) => data.data);
}

type GetSubrateTriggersTypeProps = { page: number, limit: number, filters: SubrateAdjustmentTriggerListFilters, sorting: { [k: string]: "asc" | "desc" }};

export function getSubrateAdjustmentTriggerList({
  page,
  limit,
  filters,
  sorting,
}: GetSubrateTriggersTypeProps): Promise<{ data: SubrateTriggersListItem, count: number }> {
  const [[current, direction] = []] = Object.entries(sorting);
  return requestService.get(environment.endpoints.get.getSubrateTriggersList, {
    params: {
      filters,
      sorting: current ? { current, direction } : undefined,
      page,
      limit,
    },
    paramsSerializer: (param: GetSubrateTriggersTypeProps): string => qs.stringify(param, { arrayFormat: "brackets", encode: true }),
  })
    .then(({ data: { data, count } }: { data: { data: object, count: number } }) => ({ data, count }));
}

export function changeSubrateTriggersStatus(ids: (string | number)[], isActive: boolean): Promise<void> {
  return requestService.post(environment.endpoints.post.changeSubrateTriggersStatus, {
    activate: ids.map((id: string | number) => ({ id: String(id), isActive: Number(isActive) })),
  });
}

export function deleteSubrateTriggers(ids: (number)[]): Promise<void> {
  return requestService.delete(environment.endpoints.delete.deleteCronusSubrateTrigger, {
    data: { ids },
  });
}
