/* eslint-disable import/max-dependencies */
import {
  call,
  put,
  select,
  takeEvery,
} from "redux-saga/effects";
import { setErrors, prepareFetchErrors, addNotification, getFormData, setLoading } from "@fas/ui-framework";
import { SAVE_FORM_DATA_SAGA } from "@fas/ui-framework/lib/redux/constants";
import {ExternalRedirect} from "@fas/ui-core";
import type  { AxiosError } from "axios";
import { apiMapSaveFormData } from "../../services/form";
import {SAVE_FORM_DATA_LOADING} from "../../helpers/constants";

type SaveFormDataSagaType = {
  type: typeof SAVE_FORM_DATA_SAGA,
  key: keyof typeof apiMapSaveFormData,
  redirect?: string,
}

export function* makeFetch(action: SaveFormDataSagaType) {
  try {
    yield put(setLoading(SAVE_FORM_DATA_LOADING, true));

    const fn = apiMapSaveFormData[action.key];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const data = yield select((state) => getFormData(state, action.key));

    yield call(fn, data);

    if (action.redirect) {
      ExternalRedirect({ to: action.redirect });
    }
  }
  catch (error) {
    const err = error as {errorMessage?: string} & AxiosError<{
      errors?: {
        field: string,
        error?: string,
        message?: string,
      }[],
    }>;
    if (err.isAxiosError
      && err.response
      && err.response.data
      && err.response.data.errors
      && Array.isArray(err.response.data.errors)
      && err.response.data.errors.length) {
      yield put(setErrors(prepareFetchErrors(err.response.data.errors)));
      const unfieldError = err.response.data.errors.find(({ field }: { field?: string }) => !field);
      if (unfieldError) {
        yield put(addNotification({ message: unfieldError.error || unfieldError.message || "Error", severity: "error" }));
      }
    }
    else {
      yield put(addNotification({ message: err.errorMessage || "Failed to save", severity: "error" }));
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }
  finally {
    yield put(setLoading(SAVE_FORM_DATA_LOADING, false));
  }
}

export default function* watchSaveFormDataSaga() {
  yield takeEvery(SAVE_FORM_DATA_SAGA, makeFetch);
}
