/* eslint-disable import/max-dependencies */
import {
  Box,
  Container,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import {
  FormHeader,
  SetupCard,
  AddData,
  AutocompleteWithFetchForm,
  SelectForm,
  ShowByValue,
  SwitchFieldForm,
  TextFieldForm,
} from "@fas/ui-core";
import {getNextId, useFormField as useFormFieldBase, getFormDataSaga, setFormData} from "@fas/ui-framework";
import React, {useEffect} from "react";
import {useParams, useLocation} from "react-router-dom";
import Form from "../../components/Form";
import {FORM_KEY_CPA_SUBRATE_TRIGGER, GET_FORM_DATA_LOADING, SAVE_FORM_DATA_LOADING} from "../../helpers/constants";
import ListForm from "../../components/ListForm";
import SubrateAdjustment from "./SubrateAdjustment";
import {type ListFormChildrenType} from "../../components/ListForm/ListForm";
import {useDispatch} from "../../store";
import {
  fetchAffiliates, fetchAffiliatesById,
  fetchOffers, fetchOffersById,
} from "../../services/subrateTriggerApi";
import validation from "./validation";
import useErrorMessage from "./useErrorMessage";
import {useResetFieldByFieldChange} from "./useResetFieldByFieldChange";
import {useLoading} from "./useLoading";
import {useDropdownsList} from "./useDropdownsList";
import type {Adjustment} from "./types";
import { toISOString } from "../../helpers";

function ResetFieldLogic() {
  useResetFieldByFieldChange("isIncludeSubrateInfo", "utmContent", "");
  useResetFieldByFieldChange("isIncludeSubrateInfo", "s3", "");
  useResetFieldByFieldChange("cpaCampaignId.value", "country", "");
  return null;
}

function getTomorrow(day: Date) {
  const nextDay = new Date(day);
  nextDay.setDate(day.getDate() + 1);
  return nextDay;
}

function useFillFormFromSearch() {
  const dispatch = useDispatch();
  const { search } = useLocation();
  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    async function call() {
      if (searchParams.get("affId")) {
        const [affId = { label: "", value: "" }] = await fetchAffiliatesById({ filter: searchParams.get("affId") || "" });
        dispatch(setFormData(FORM_KEY_CPA_SUBRATE_TRIGGER, {
          affId,
        }));
      }
      if (searchParams.get("cpaCampaignId")) {
        const [cpaCampaignId = { label: "", value: "" }] = await fetchOffersById({ filter: searchParams.get("cpaCampaignId") || "" });
        dispatch(setFormData(FORM_KEY_CPA_SUBRATE_TRIGGER, {
          cpaCampaignId,
        }));
      }
    }

    // eslint-disable-next-line no-void
    void call().then(() => {
      dispatch(setFormData(FORM_KEY_CPA_SUBRATE_TRIGGER, {
        country: searchParams.get("country") || "",
        s3: searchParams.get("s3") || "",
        utmContent: searchParams.get("utmContent") || "",
      }));
    });
  }, [dispatch, search]);

}

function SubrateAdjustmentTriggerForm() {
  const { id: objectId} = useParams();
  const dispatch = useDispatch();
  const confirm = useConfirm();

  useEffect(() => {
    if (objectId) {
      dispatch(getFormDataSaga(FORM_KEY_CPA_SUBRATE_TRIGGER, objectId));
    }
  }, [dispatch, objectId]);

  useFillFormFromSearch();


  const loading = useLoading(GET_FORM_DATA_LOADING, SAVE_FORM_DATA_LOADING);
  const isDisabledFields = Boolean(objectId) || loading;
  const {value: affiliate} = useFormFieldBase<{ label: string, value: string }>(FORM_KEY_CPA_SUBRATE_TRIGGER, "affId");
  const affIdErrorMessage = useErrorMessage("affId.value", "affId");
  const cpaCampaignIdErrorMessage = useErrorMessage("cpaCampaignId", "cpaCampaignId.value");
  const adjustmentsErrorMessage = useErrorMessage("adjustments");
  const {value: offer} = useFormFieldBase<{ label: string, value: string }>(FORM_KEY_CPA_SUBRATE_TRIGGER, "cpaCampaignId");
  const countriesList: { label: string, value: string }[] = useDropdownsList("offerCountry", offer?.value).map(v => ({...v, value: v.value || ""}));

  const isIncludeSubrateInfo = useFormFieldBase<{ label: string, value: string }>(FORM_KEY_CPA_SUBRATE_TRIGGER, "isIncludeSubrateInfo");
  const showAllSubrateTextDisable = "The specified setup implies that any changes you implement will affect the entire scope of the specified offer. Please confirm your action.";

  return (
    <Form id={FORM_KEY_CPA_SUBRATE_TRIGGER} validate={validation} redirectOnSave="/subrate/triggersList">
      <ResetFieldLogic />
      <FormHeader
        title={objectId ? `Edit subrate trigger: ${String(objectId)}` : "Create subrate trigger"}
        loading={loading}
      >
        <SwitchFieldForm
          name="isActive"
          label="Is active"
          disabled={loading}
        />
      </FormHeader>
      <Box sx={{ height: "calc(100vh - 162px)", overflowY: "auto", mt: 3 }}>
        <Container maxWidth="md" sx={{pb: 1, mt: -3}}>
          <SetupCard title="General options">
            <>
              <AutocompleteWithFetchForm
                name="affId"
                label="Affiliate name"
                error={affIdErrorMessage}
                fetchMethod={fetchAffiliates}
                disabled={isDisabledFields}
                renderOption={(props, option: { label: string, value: string }) => (
                  <li {...props} key={option.value}>
                    {`(${option.value}) ${option.label}`}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: affiliate?.value && (
                        <InputAdornment position="end">
                          {`(${affiliate.value})`}
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <AutocompleteWithFetchForm
                name="cpaCampaignId"
                label="Offer name"
                error={cpaCampaignIdErrorMessage}
                fetchMethod={fetchOffers}
                searchLimit={2}
                disabled={isDisabledFields}
                renderOption={(props, option: { label: string, value: string }) => (
                  <li {...props} key={option.value}>
                    {`(${option.value}) ${option.label}`}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: offer?.value && (
                        <InputAdornment position="end">
                          {`(${offer?.value})`}
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <SelectForm
                name="country"
                label={!offer.value ? "Select offer first" : "Country"}
                options={countriesList}
                disabled={isDisabledFields || !offer?.value}
              />
              <SwitchFieldForm
                name="isIncludeSubrateInfo"
                label="Show subrate info"
                disabled={isDisabledFields}
                onChange={(value) => {
                  if (!value) {
                    confirm({
                      title: "",
                      content: <Box p={1}>{ showAllSubrateTextDisable }</Box>,
                    })
                      .then(() => {
                        isIncludeSubrateInfo.onChange(value);
                      })
                      .catch(() => null);
                  }
                  else{
                    isIncludeSubrateInfo.onChange(value);
                  }

                }}
              />
              
              <ShowByValue name="isIncludeSubrateInfo">
                <TextFieldForm
                  name="utmContent"
                  label="utm_content"
                  disabled={isDisabledFields}
                />
                <TextFieldForm
                  name="s3"
                  label="S3"
                  disabled={isDisabledFields}
                />
              </ShowByValue>
            </>
          </SetupCard>
          <ListForm name="adjustments">
            {({value, append, remove, update}: ListFormChildrenType<Adjustment>) => (
              <>
                {value.length === 0 && (
                  <AddData
                    title="You have no adjustments yet."
                    linkText="+ ADD NEW Adjustments"
                    error={Boolean(adjustmentsErrorMessage)}
                    onClick={() => {
                      append({
                        id: getNextId(value.map(({ id: itemId}) => itemId)),
                        date: toISOString(getTomorrow(new Date())),
                      });
                    }}
                  />
                )}
                {value.length > 0 && (
                  <SetupCard
                    title="Adjustments"
                    showMainBtn
                    mainBtnText="Add rule"
                    onMainBtnClick={() => {
                      append({
                        id: getNextId(value.map(({ id: itemId}) => itemId)),
                        date: toISOString(getTomorrow(new Date())),
                      });
                    }}
                  >
                    {value.map((item, index) => (
                      <SubrateAdjustment
                        key={item.id}
                        value={item}
                        index={index}
                        remove={remove}
                        update={update}
                      />
                    ))}
                  </SetupCard>
                )}
              </>
            )}
          </ListForm>
        </Container>
      </Box>
    </Form>
  );
}

export default SubrateAdjustmentTriggerForm;
