import type React from "react";

type Breadcrumbs = {
  path: string,
  title: string,
  component?: React.ReactNode,
}

export default function getBreadcrumbs(pathname: string): Array<Breadcrumbs> {
  const breadcrumbs = [
    {
      path: "/subrate",
      title: "Subrates",
    },
  ];

  if(pathname.includes("create")) {
    breadcrumbs.push({
      path: "/subrate/create",
      title: "Create trigger",
    });
  }
  if(pathname.includes("triggersList")) {
    breadcrumbs.push({
      path: "/subrate/triggersList",
      title: "Triggers list",
    });
  }
  if(pathname.includes("triggerReports")) {
    breadcrumbs.push({
      path: "/subrate/triggerReports",
      title: "Trigger reports",
    });
  }

  return breadcrumbs;
}
