export const FORM_KEY_CPA_SUBRATE_TRIGGER = "FORM_KEY_CPA_SUBRATE_TRIGGER";
export const CPA_SUBRATE_TRIGGER_REPORT_TABLE = "CPA_SUBRATE_TRIGGER_REPORT_TABLE";

export const GET_FORM_DATA_LOADING = "GET_FORM_DATA_LOADING" as const;
export const SAVE_FORM_DATA_LOADING = "SAVE_FORM_DATA_LOADING" as const;
export const GET_TABLE_DATA_LOADING = "GET_TABLE_DATA_LOADING" as const;

export const CPA_SUBRATE_TRIGGERS_TABLE = "SUBRATE_TRIGGERS_TABLE";

export const GET_SUBRATE_TRIGGERS_LIST_SAGA = "GET_SUBRATE_TRIGGERS_LIST";
export const CHANGE_IS_ACTIVE_SAGA = "CHANGE_IS_ACTIVE_SAGA";
export const DELETE_TRIGGER_SAGA = "DELETE_TRIGGER_SAGA";
