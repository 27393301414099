import {Box, IconButton} from "@mui/material";
import DateRangeIcon from "@mui/icons-material/DateRange";

// eslint-disable-next-line import/prefer-default-export
export const DatepickerFilter = (props: { onIconClick: () => void }) => {
  const {
    onIconClick,
  } = props;
  return (
    <Box>
      <IconButton
        sx={{paddingLeft: 0}}
        size="small"
        onClick={onIconClick}
        data-testid="calendar-icon"
      >
        <DateRangeIcon/>
      </IconButton>
    </Box>
  );
};
