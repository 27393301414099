// @flow
/* eslint-disable import/max-dependencies */
import { FORM_KEY_CPA_SUBRATE_TRIGGER} from "../../helpers/constants";
import {
  getCronusSubrateTrigger,
  saveCronusSubrateTrigger,
} from "../subrateTriggerApi";

export const apiMapSaveFormData = {
  [FORM_KEY_CPA_SUBRATE_TRIGGER]: saveCronusSubrateTrigger,
};

export const apiMapGetFormData = {
  [FORM_KEY_CPA_SUBRATE_TRIGGER]: getCronusSubrateTrigger,
};
