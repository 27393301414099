import type { ReactElement } from "react";
import { useFormFieldArray, type UseFormFieldArrayReturnType } from "@fas/ui-framework";
import { useFormContext } from "@fas/ui-core";

export type ListFormChildrenType<T extends object> = UseFormFieldArrayReturnType<T>

type Props<T extends object> = {
  name: string,
  children: (p: UseFormFieldArrayReturnType<T>) => ReactElement,
};

export default function ListForm<T extends object>({name, children}: Props<T>) {
  const { formId } = useFormContext();
  const prop = useFormFieldArray<T>(formId, name);
  return children(prop);
}
