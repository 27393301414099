import { Form, useFormContext } from "@fas/ui-core";
import {useFormField as useFormFieldBase, withForm} from "@fas/ui-framework";

export default withForm(Form);

export function useFormField<T>(name: string) {
  const { formId } = useFormContext();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-argument
  return useFormFieldBase<T>(formId, name);
}
