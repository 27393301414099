import Dayjs from "dayjs";
import utc  from "dayjs/plugin/utc";

Dayjs.extend(utc);

export function formatDateUTC(date: Parameters<typeof Dayjs>[0]): string {
  const d = Dayjs(date);
  if (d.isValid()) {
    return d.utc().format("YYYY-MM-DD HH:mm");
  }
  return "";
}
export function formatDate(date: Parameters<typeof Dayjs>[0]): string {
  const d = Dayjs(date);
  if (d.isValid()) {
    return d.format("YYYY-MM-DD HH:mm");
  }
  return "";
}
export function formatToFront(date: Parameters<typeof Dayjs>[0]): string {
  return Dayjs(date).format("YYYY-MM-DDTHH:mm:00.000Z");
}
export function toISOString(date: Parameters<typeof Dayjs>[0]): string {
  return Dayjs(date).toISOString();
}
