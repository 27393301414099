import {DateRangePickerV2} from "@fas/ui-core";
import {DatepickerFilter} from "./DatepickerFilter";

// eslint-disable-next-line import/prefer-default-export
export const DatePickerSearchComponent = ({onChange, value}: {
  onChange: (p: { from: string; to: string }) => void,
  value: { from: string, to: string }
}) => {
  const changeDates: any = ({startDate: start, endDate: end}: { startDate: any, endDate: any }) => {
    onChange({
      from: new Date(start).toLocaleDateString(
        "fr-CA",
        {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          timeZone: "utc",
          hour12: false,
        }
      ),
      to: new Date(end).toLocaleDateString(
        "fr-CA",
        {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          timeZone: "utc",
          hour12: false,
        }
      ),
    }
    );
  };
  return (
    <DateRangePickerV2
      startDate={value.from}
      endDate={value.to}
      minDate={`01-01-${new Date().getFullYear() - 5}`}
      disableFuture
      onChangeDate={changeDates}
      DateInputComponent={DatepickerFilter}
    />
  );
};
