import { configureStore, type Reducer, type AnyAction, createImmutableStateInvariantMiddleware } from "@reduxjs/toolkit";
import {
  tableReducer,
  initTableState,
  formReducer,
  initFormState,
  loadingReducer,
  initLoadingState,
  notificationsReducer,
  initNotificationsState,
  errorsReducer,
  initErrorsState,
} from "@fas/ui-framework";
import {
  type TypedUseSelectorHook,
  useSelector as useReduxSelector,
  useDispatch as useReduxDispatch,
} from "react-redux";
import createSagaMiddleware from "redux-saga";
import tdsReducer from "./slices/tds";
import {
  CPA_SUBRATE_TRIGGER_REPORT_TABLE,
  FORM_KEY_CPA_SUBRATE_TRIGGER,
  CPA_SUBRATE_TRIGGERS_TABLE,
} from "./helpers/constants";
import mainSaga from "./saga";

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    tds: tdsReducer,
    notifications: notificationsReducer as Reducer<unknown, AnyAction>,
    errors: errorsReducer as Reducer<unknown, AnyAction>,
    loading: loadingReducer as Reducer<unknown, AnyAction>,
    tables: tableReducer as Reducer<unknown, AnyAction>,
    form: formReducer as Reducer<unknown, AnyAction>,
  },
  preloadedState: {
    notifications: initNotificationsState(),
    errors: initErrorsState(),
    loading: initLoadingState(),
    tables: initTableState({
      [CPA_SUBRATE_TRIGGERS_TABLE]: {
        pageSize: 20,
      },
      [CPA_SUBRATE_TRIGGER_REPORT_TABLE]: {
        pageSize: 20,
        filters: {
          finishedAt: {
            from: new Date().toLocaleDateString(
              "fr-CA",
              {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                timeZone: "utc",
                hour12: false,
              }
            ),
            to: new Date().toLocaleDateString(
              "fr-CA",
              {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                timeZone: "utc",
                hour12: false,
              }
            ),
          },
        },
      },
    }),
    form: initFormState({
      [FORM_KEY_CPA_SUBRATE_TRIGGER]: {
        affId: { value: "", label: ""},
        cpaCampaignId: { value: "", label: ""},
        country: "",
        utmContent: "",
        s3: "",
        isIncludeSubrateInfo: true,
        isActive: true,
        adjustments: [],
      },
    }),
  },
  middleware: [
    createImmutableStateInvariantMiddleware({}),
    sagaMiddleware,
  ],
});

sagaMiddleware.run(mainSaga);

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch


export const useDispatch = () => useReduxDispatch<AppDispatch>();
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
