// @flow
import { call, put, takeEvery } from "redux-saga/effects";
import { addNotification, parseResponse, setLoading, setTableSelection } from "@fas/ui-framework";
import type { AnyAction } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit/src/createAction";
import { CHANGE_IS_ACTIVE_SAGA } from "../../helpers/constants";

export type IsActiveSagaConfig = {
  tableKey: string,
  updateMethod: (ids: (string|number)[], isActive: boolean) => Promise<unknown>,
  postfixAction: () => AnyAction,
};

export function* changeIsActive(
  config: IsActiveSagaConfig,
  action: PayloadAction<{ids: (string|number)[], isActive: boolean}>
) {
  const { ids, isActive } = action.payload;

  try {
    yield put(setLoading(config.tableKey, true));
    yield call(config.updateMethod, ids, isActive);
    yield put(setTableSelection(config.tableKey, []));
    yield put(addNotification({
      message: `Items ${isActive ? "activated" : "deactivated"} successfully`,
      severity: "success",
    }));
  }
  catch (err) {
    yield put(addNotification({
      message: parseResponse(err) || "An error occured while updating items",
      severity: "error",
    }));
    // eslint-disable-next-line
    console.error(err);
  }
  finally {
    yield put(setLoading(config.tableKey, false));
    yield put(config.postfixAction());
  }
}

export default function getIsActiveSaga(config: IsActiveSagaConfig) {
  return function* watchChangeIsActiveSaga() {
    yield takeEvery(CHANGE_IS_ACTIVE_SAGA, changeIsActive, config);
  };
}
