import {useEffect, useState} from "react";
import { type DropDownsOptions, getSubratesDropdowns} from "../../services/subrateTriggerApi";

// eslint-disable-next-line import/prefer-default-export
export function useDropdownsList(type: string, filter: string) {
  const [list, setList] = useState<DropDownsOptions>([]);

  useEffect(() => {
    const call = async (): Promise<void> => {
      const newList = await getSubratesDropdowns({
        type,
        filter,
      });

      setList(newList);
    };
    setList([]);
    if (filter) {
      // eslint-disable-next-line no-void
      void call();
    }
  }, [type, filter]);

  return list;
}
