import {Box, Card, CardContent, Typography} from "@mui/material";
import {Link} from "react-router-dom";

// eslint-disable-next-line import/prefer-default-export
export function MenuCard(props: {
  text: string,
  links: { url: string; text: string }[] | undefined,
}) {
  const {text, links} = props;
  return (
    <Box mx={8} key={text}>
      <Card sx={{ minWidth: 200, minHeight: 200 }}>
        <CardContent>
          <Typography variant="h5" component="div">
            {text}
          </Typography>
          {links?.map(({ url, text: label }) => (
            <Box my={2} key={url}>
              <Link to={url}>{label}</Link>
            </Box>
          ))}
        </CardContent>
      </Card>
    </Box>
  );
}
