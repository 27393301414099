import {useEffect, useRef} from "react";
import {GET_FORM_DATA_LOADING, SAVE_FORM_DATA_LOADING} from "../../helpers/constants";
import {useFormField} from "../../components/Form/Form";
import {useLoading} from "./useLoading";

// eslint-disable-next-line import/prefer-default-export
export function useResetFieldByFieldChange<T = string>(watchFieldName: string, targetFieldName: string, resetValue: T) {
  const loading: boolean = useLoading(GET_FORM_DATA_LOADING, SAVE_FORM_DATA_LOADING);
  const { onChange, value: targetValue } = useFormField<T>(targetFieldName);
  const { value } = useFormField<T>(watchFieldName);

  const isMount = useRef<boolean>(true);
  useEffect(() => {
    if (isMount.current) {
      isMount.current = false;
      return;
    }

    if (loading || targetValue === resetValue) return;
    onChange(resetValue);
  }, [value]);
}
