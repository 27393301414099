/* eslint-disable import/max-dependencies */
import {Box} from "@mui/material";
import type {Column} from "@fas/ui-core";
import { ShowMoreText, Table } from "@fas/ui-core";
import {getTableDataSaga, useTable} from "@fas/ui-framework";
import {useDispatch} from "react-redux";
// eslint-disable-next-line import/no-extraneous-dependencies
import { makeStyles } from "@mui/styles";
import {CPA_SUBRATE_TRIGGER_REPORT_TABLE, GET_TABLE_DATA_LOADING} from "../../helpers/constants";
import {useLoading} from "../SubrateAdjustmentTriggerForm/useLoading";
import {DatePickerSearchComponent} from "./DatePickerSearchComponent";
import {StatusSearchComponent} from "./StatusSearchComponent";
import { formatDate, formatDateUTC } from "../../helpers";

export type DataRow = {
  id: number,
  affId: string,
  cpaCampaignId: string,
  country: string,
  utmContent: string,
  s3: string,
  subrateOption: {
    [key: string]: string,
  },
  status: string,
  finishedAt: string,
  error: string,
}

export type Filters = {
  id: string,
  affId: string,
  country: string,
  utmContent: string,
  s3: string,
  subrateOption: {
    [key: string]: string,
  },
  status: string,
  finishedAt: { from: string, to: string }
}

const useStyles = makeStyles(() => ({
  id: {
    paddingLeft: "10px",
  },
  error: {
    width: "150px",
  },
}));

function SubrateAdjustmentTriggerReport() {
  const tableProps = useTable<DataRow, Filters>(CPA_SUBRATE_TRIGGER_REPORT_TABLE);
  const dispatch = useDispatch();
  const classes = useStyles();
  const columns: Column<DataRow, Filters>[] = [
    {
      field: "id",
      label: "Rule id",
      sortable: false,
      searchable: true,
      className: classes.id,
    },
    {
      field: "affId",
      label: "Affiliate id",
      sortable: false,
      searchable: true,
    },
    {
      field: "cpaCampaignId",
      label: "Offer id",
      sortable: false,
      searchable: true,
    },
    {
      field: "country",
      label: "Country",
      sortable: false,
      searchable: true,
      render: ({ country }) => country || "all",
    },
    {
      field: "utmContent",
      label: "utm_content",
      sortable: false,
      searchable: true,
    },
    {
      field: "s3",
      label: "s3",
      sortable: false,
      searchable: true,
    },
    {
      field: "subrateOption",
      label: "New subrates option",
      sortable: false,
      searchable: false,
      render: (row: DataRow): React.ReactNode => (
        <pre style={{ marginBottom: "8px", marginTop: "8px" }}>
          {Object.keys(row.subrateOption)
            .map(key => `${key}: ${row.subrateOption[key]}`)
            .join("\n")}
        </pre>
      ),
    },
    {
      field: "status",
      label: "Status",
      sortable: true,
      searchable: true,
      SearchComponent: StatusSearchComponent,
    },
    {
      field: "finishedAt",
      label: "Date",
      sortable: true,
      searchable: true,
      SearchComponent: DatePickerSearchComponent,
      render: (row: DataRow) => <span title={`utc=${formatDateUTC(row.finishedAt)}`}>{formatDate(row.finishedAt)}</span>,
    },
    {
      field: "error",
      label: "Detailed error",
      sortable: false,
      searchable: false,
      render: (row: DataRow) => <ShowMoreText data={row.error || ""} />,
      className: classes.error,
    },
  ];
  const handleLoad = () => (dispatch(getTableDataSaga(CPA_SUBRATE_TRIGGER_REPORT_TABLE)));
  const isTableLoading = useLoading(GET_TABLE_DATA_LOADING);

  return (
    <Box p={2}>
      <Table
        {...tableProps}
        selected={[]}
        title="Subrate adjustment report"
        allCheckboxVisible={false}
        allSelectAvailable={() => false}
        columns={columns}
        onLoad={handleLoad}
        rowSelectAvailable={() => false}
        isLoading={isTableLoading}
      />
    </Box>
  );
}

export default SubrateAdjustmentTriggerReport;
