import {Box} from "@mui/material";
import {menu} from "../../modules/Menu";
import {MenuCard} from "../../components/MenuCard/MenuCard";

function MainPage() {
  const menuLinks = menu.filter(({ url }) => url !== "/");
  return (
    <Box
      mt={16}
      display="flex"
      flexWrap="wrap"
      justifyContent="space-evenly"
    >
      {menuLinks.map(({ text, links }) => (
        <MenuCard text={text} links={links} />
      ))}
    </Box>
  );
}

export default MainPage;
