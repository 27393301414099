// @flow
import { call, put, takeEvery } from "redux-saga/effects";
import { GET_FORM_DATA_SAGA } from "@fas/ui-framework/lib/redux/constants";
import { addNotification, setFormData, setLoading } from "@fas/ui-framework";
import type {AxiosError} from "axios";
import { apiMapGetFormData } from "../../services/form";
import {GET_FORM_DATA_LOADING} from "../../helpers/constants";

export type GetFormDataSagaType = {
  type: typeof GET_FORM_DATA_SAGA,
  key: keyof typeof apiMapGetFormData,
  id: string,
}

export function* makeFetch(action: GetFormDataSagaType) {
  try {
    yield put(setLoading(GET_FORM_DATA_LOADING, true));
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const data = yield call(apiMapGetFormData[action.key], action.id);
    yield put(setFormData(action.key, data));
  }
  catch (error) {
    const err = error as {errorMessage?: string} & AxiosError;
    yield put(addNotification({ message: err.errorMessage || "Failed to fetch", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(err);
    yield put(setFormData(action.key, { isError: true }));
  }
  finally {
    yield put(setLoading(GET_FORM_DATA_LOADING, false));
  }
}

export default function* watchGetFormDataSaga() {
  yield takeEvery(GET_FORM_DATA_SAGA, makeFetch);
}
