/* eslint-disable import/max-dependencies */
import {
  type FC,
} from "react";
import { useDispatch } from "react-redux";
import { useTable, type TableProps, getTableDataSaga } from "@fas/ui-framework";
import { Box } from "@mui/material";
// eslint-disable-next-line import/no-extraneous-dependencies
import { makeStyles } from "@mui/styles";
import { Table, ExternalRedirect } from "@fas/ui-core";
import type { Column } from "@fas/ui-core";
import { GET_TABLE_DATA_LOADING, CPA_SUBRATE_TRIGGERS_TABLE } from "../../helpers/constants";
import type {
  SubrateTriggersListItem,
  Classes,
  Props as TableActionsProps,
  SubrateAdjustmentTriggerListFilters,
} from "./SubrateAdjustmentTriggerList.types";
import getColumns from "./getColumns";
import TableActions from "./TableActions";
import { changeIsActiveSaga, deleteTriggerSaga } from "../../slices/subrate";
import { useLoading } from "../SubrateAdjustmentTriggerForm/useLoading";

type Props = Record<string, never>;

const useStyles: () => Classes = makeStyles(() => ({
  smallTableCol: {
    width: "80px",
    minWidth: "80px",
  },
  mediumTableCol: {
    minWidth: "130px",
  },
}));

const actionsProps: TableActionsProps<typeof CPA_SUBRATE_TRIGGERS_TABLE> = {
  tableKey: CPA_SUBRATE_TRIGGERS_TABLE,
  editPath: "/subrate/:id",
  viewPath: "/subrate/:id",
  onChangeIsActive: changeIsActiveSaga,
  onDelete: deleteTriggerSaga,
};

const SubrateAdjustmentTriggerList: FC<Props> = () => {
  const classes: Classes = useStyles();
  const tableProps: TableProps<SubrateTriggersListItem> = useTable(CPA_SUBRATE_TRIGGERS_TABLE);
  const dispatch = useDispatch();

  const handleLoad = () => (dispatch(getTableDataSaga(CPA_SUBRATE_TRIGGERS_TABLE)));

  const onCreate = () => {
    ExternalRedirect({ to: "/subrate/create" });
  };

  const columns: Array<Column<SubrateTriggersListItem, SubrateAdjustmentTriggerListFilters>> = getColumns(classes);
  const isTableLoading = useLoading(GET_TABLE_DATA_LOADING);

  return (
    <Box p={3}>
      <Table
        {...tableProps}
        title="Subrate triggers list"
        columns={columns}
        rowSelectAvailable={(): boolean => true}
        allSelectAvailable={(): boolean => true}
        onCreate={onCreate}
        onLoad={handleLoad}
        Actions={<TableActions {...actionsProps} />}
        isLoading={isTableLoading}
      />
    </Box>
  );
};

export default SubrateAdjustmentTriggerList;
