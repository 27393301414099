import type { ObjectSchema } from "joi";
import type { ErrorsMapper } from "@fas/ui-framework";

function joiResolver<T extends object>(schema: ObjectSchema<T>) {
  return function resolve(values: T): ErrorsMapper {
    const result = schema.validate(values, { abortEarly: false });
    const details = result?.error?.details || [];
    return details.reduce((acc, error) => ({
      ...acc,
      [error.context?.label || error.path.join(".")]: { message: error.message },
    }), {});
  };
}

export default joiResolver;
