import { AppNavbar } from "@fas/ui-core";
import environment from "environment";
import type {FunctionComponent} from "react";
import { redirect } from "react-router-dom";
import {userService} from "../../services/user/user";
import userApi from "../../services/userApi/userApi";


// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import appLinks from "./appLinks";

type MenuProps = {
  breadcrumbs?: Array<{
    path: string,
    title: string,
  }>
}

export const menu = [
  {
    text: "Home",
    url: "/",
  },
  {
    text: "Subrate section",
    links: [
      {
        url: "/subrate/create",
        text: "Create trigger",
        permissions: [],
      },
      {
        url: "/subrate/triggersList",
        text: "Triggers list",
        permissions: [],
      },
      {
        url: "/subrate/triggerReports",
        text: "Trigger reports",
        permissions: [],
      },
    ],
    permissions: [],
  },
];

const Menu: FunctionComponent<MenuProps> = ({ breadcrumbs = [] }: MenuProps) => {
  const menuLinks = menu.filter(({ permissions = [] }): boolean => userService.can(permissions));
  const handleLogout: () => void = () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    userApi.logout(environment.logoutUrl).catch(() => {
      redirect("/error");
    });
  };
  return (
    <AppNavbar
      appName="Cronus Admin"
      menuLinks={menuLinks}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      appLinks={appLinks}
      breadcrumbs={breadcrumbs}
      onLogout={handleLogout}
    />
  );
};

export default Menu;
