export default (baseUrl: string) => ({
  baseUrl,
  links: {},
  logoutUrl: `${baseUrl}/logout`,
  errorWatcher: {
    listenedConsoleMethods: ["warn", "debug", "error", "info"],
  },
  endpoints: {
    get: {
      apiKey: `${baseUrl}/api/login`,
      loginUrl: `${baseUrl}/login`,
      getSubratesDropdowns: `${baseUrl}/api/v1/dictionary/subrate/dropdown`,
      getCronusSubrateTrigger: `${baseUrl}/api/v1/cronus/trigger/subrate/{id}`,
      getSubrateAdjustmentTriggerReport: `${baseUrl}/api/v1/cronus/reportLog/subrate`,
      getSubrateTriggersList: `${baseUrl}/api/v1/cronus/trigger/subrate`,
    },
    post: {
      createCronusSubrateTrigger: `${baseUrl}/api/v1/cronus/trigger/subrate`,
      changeSubrateTriggersStatus: `${baseUrl}/api/v1/cronus/trigger/subrate/bulkActivate`,
    },
    put: {
      updateCronusSubrateTrigger:`${baseUrl}/api/v1/cronus/trigger/subrate/{id}`,
    },
    delete: {
      deleteCronusSubrateTrigger: `${baseUrl}/api/v1/cronus/trigger/subrate`,
    },
    redirect: {},
  },
  appLinks: {
    dmp: "https://rel-admin-dmp.insigit.com/",
    jus: "https://jus.rel.insigit.com/",
    cpa: "https://cpa-admin.rel.insigit.com/",
    webPush: "https://webpush.rel.noclef.com/",
    adsBox: "https://adsbox.rel.insigit.com/",
    reports: "https://reports.rel.insigit.com/managersReport",
    crm: "https://crm.rel.insigit.com/",
  },
});
