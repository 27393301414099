// eslint-disable-next-line
import {
  ViewLink,
} from "@fas/ui-core";
import type { Column } from "@fas/ui-core";
import { Check, Close } from "@mui/icons-material";
import type {
  SubrateTriggersListItem,
  Classes,
  SubrateAdjustmentTriggerListFilters,
} from "./SubrateAdjustmentTriggerList.types";
import { formatDate, formatDateUTC } from "../../helpers";

export default function getColumns(
  classes: Classes
): Array<Column<SubrateTriggersListItem, SubrateAdjustmentTriggerListFilters>> {
  return [
    {
      field: "id",
      label: "Id",
      sortable: false,
      searchable: true,
      className: classes.smallTableCol,
      render: ({ id }: SubrateTriggersListItem) => (
        <ViewLink id={String(id)} name={String(id)} viewPath="/subrate/:id" />
      ),
    },
    {
      field: "isActive",
      label: "Is active",
      searchable: false,
      sortable: false,
      className: classes.smallTableCol,
      render: ({ isActive }) => isActive ? <Check color="success" /> : <Close color="error" />,
    },
    {
      field: "affId",
      label: "Affiliate id",
      searchable: true,
      sortable: false,
      className: classes.mediumTableCol,
    },
    {
      field: "cpaCampaignId",
      label: "Offer id",
      sortable: false,
      searchable: true,
      className: classes.mediumTableCol,
    },
    {
      field: "country",
      label: "Country",
      sortable: false,
      searchable: true,
      className: classes.mediumTableCol,
      render: ({ country }) => country || "all",
    },
    {
      field: "utmContent",
      label: "utmContent",
      sortable: false,
      searchable: true,
      className: classes.mediumTableCol,
    },
    {
      field: "s3",
      label: "S3",
      sortable: false,
      searchable: true,
      className: classes.mediumTableCol,
    },
    {
      field: "createdBy",
      label: "Created by",
      sortable: false,
      searchable: false,
    },
    {
      field: "createdAt",
      label: "Created at",
      sortable: true,
      searchable: false,
      render: (row: SubrateTriggersListItem) => <span title={`utc=${formatDateUTC(row.createdAt)}`}>{formatDate(row.createdAt)}</span>,
    },
    {
      field: "updatedAt",
      label: "Updated at",
      sortable: true,
      searchable: false,
      render: (row: SubrateTriggersListItem) => <span title={`utc=${formatDateUTC(row.updatedAt)}`}>{formatDate(row.updatedAt)}</span>,
    },
  ];
}
