import type {FunctionComponent} from "react";
import { Outlet, useLocation } from "react-router-dom";
import Menu from "../../modules/Menu/Menu";
import getBreadcrumbs from "./breadcrumbs";

const SubrateLayout: FunctionComponent = () => {
  const { pathname } = useLocation();
  const breadcrumbs = getBreadcrumbs(pathname);

  return (
    <>
      <Menu breadcrumbs={breadcrumbs}/>
      <Outlet/>
    </>
  );
};

export default SubrateLayout;
