// @flow
import { all, call } from "redux-saga/effects";
import { getTableDataSaga } from "@fas/ui-framework";
import watchSaveFormDataSaga from "./sagas/saveFormDataSaga";
import watchGetFormDataSaga from "./sagas/getFormDataSaga";
import watchGetTableDataSaga from "./sagas/getTableDataSaga";
import getIsActiveSaga from "./sagas/changeIsActiveSaga";
import deleteTriggerSaga from "./sagas/deleteTriggerSaga";
import { CPA_SUBRATE_TRIGGERS_TABLE } from "./helpers/constants";
import { changeSubrateTriggersStatus, deleteSubrateTriggers } from "./services/subrateTriggerApi";

const changeIsActiveSaga = getIsActiveSaga({
  tableKey: CPA_SUBRATE_TRIGGERS_TABLE,
  updateMethod: changeSubrateTriggersStatus,
  postfixAction: () => getTableDataSaga(CPA_SUBRATE_TRIGGERS_TABLE),
});

const deleteSubrateTriggerSaga = deleteTriggerSaga({
  tableKey: CPA_SUBRATE_TRIGGERS_TABLE,
  updateMethod: deleteSubrateTriggers,
  postfixAction: () => getTableDataSaga(CPA_SUBRATE_TRIGGERS_TABLE),
});

export default function* mainSaga() {
  yield all([
    call(watchGetFormDataSaga),
    call(watchSaveFormDataSaga),
    call(watchGetTableDataSaga),
    call(changeIsActiveSaga),
    call(deleteSubrateTriggerSaga),
  ]);
}
