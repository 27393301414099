/* eslint-disable import/max-dependencies */
import { useEffect, useState } from "react";
import "./App.css";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { ConfirmProvider } from "material-ui-confirm";
import { mtuDefaultTheme as theme } from "@fas/ui-themes";
import { Provider } from "react-redux";
import environment from "environment";
import {
  Navigate,
  BrowserRouter,
  Routes,
  Route,
  redirect,
} from "react-router-dom";
import { Error, Loader, AccessDenied } from "@fas/ui-core";
import userApi from "./services/userApi";
import Menu from "./modules/Menu/Menu";
import { store } from "./store";
import MainPage from "./pages/MainPage";
import SubrateAdjustmentTriggerForm from "./pages/SubrateAdjustmentTriggerForm";
import Subrate from "./layouts/Subrate";
import NotificationsContainer from "./components/NotificationsContainer/index";
import SubrateAdjustmentTriggerReport from "./pages/SubrateAdjustmentTriggerReport";
import SubrateMainPage from "./pages/SubrateMainPage";
import SubrateAdjustmentTriggerList from "./pages/SubrateAdjustmentTriggerList";


function App() {
  const [loading, setLoading] = useState(true);
  const helmetContext = {};
  useEffect(() => {
    userApi.getUserInfo(environment.endpoints.get.apiKey)
      .then(() => setLoading(false))
      .catch(() => {
        redirect("/error");
      } );
  }, []);

  return (
    <HelmetProvider context={helmetContext}>
      <Helmet >
        <title>Cronus admin</title>
        <meta name="description" content="Admin panel for cronus" />
      </Helmet>
      <div className="App">
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <ConfirmProvider>
              <Provider store={store}>
                <NotificationsContainer />
                { loading && (<Loader loading size={35} mt={37} />)}
                { !loading && (
                  <BrowserRouter>
                    <Routes>
                      <Route path="/" element={<><Menu/><MainPage/></>} />
                      <Route path="/404" element={<Error/>} />
                      <Route path="/accessDenied" element={<AccessDenied/>} />
                      <Route path="/subrate" element={<Subrate/>}>
                        <Route path="/subrate/triggersList" element={<SubrateAdjustmentTriggerList />} />
                        <Route path="/subrate/triggerReports" element={<SubrateAdjustmentTriggerReport/>} />
                        <Route path="/subrate/create" element={<SubrateAdjustmentTriggerForm />} />
                        <Route path="/subrate/:id" element={<SubrateAdjustmentTriggerForm />} />
                        <Route path="/subrate" element={<SubrateMainPage />} />
                      </Route>
                      <Route path="*" element={<Navigate to="/404" replace />} />
                    </Routes>
                  </BrowserRouter>
                ) }
              </Provider>
            </ConfirmProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </div>
    </HelmetProvider>
  );
}

export default App;
