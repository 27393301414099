import { useSelector, useDispatch } from "react-redux";
import {
  TableActions,
  TableActionGroup,
  SimpleTableActionButton,
  ConfirmTableActionButton,
  ExternalRedirect,
} from "@fas/ui-core";
import { getTableSelections } from "@fas/ui-framework";
import {
  Edit,
  Block,
  CheckCircleOutline,
  Delete,
} from "@mui/icons-material";
import type { StoreWithTable, SubrateTriggersListItem, Props } from "./SubrateAdjustmentTriggerList.types";

function TablesActions<T extends string>({
  tableKey,
  editPath,
  viewPath,
  onChangeIsActive,
  onDelete,
}: Props<T>) {
  const items: SubrateTriggersListItem[] = useSelector(
    (state: StoreWithTable): SubrateTriggersListItem[] => getTableSelections(state, tableKey)
  );
  const dispatch = useDispatch();
  return (
    <TableActions>
      <TableActionGroup>
        <SimpleTableActionButton
          tooltip="Edit"
          disabled={items.length !== 1}
          onClick={(): void => ExternalRedirect({ to: editPath.replace(":id", String(items[0].id)), target: "_blank" })}
          Icon={<Edit />}
        />
      </TableActionGroup>
      <TableActionGroup>
        <ConfirmTableActionButton
          tooltip="Activate"
          items={items}
          confirmTitle="Activating items"
          confirmText="Are you sure you want to activate "
          viewPath={viewPath}
          onClick={() => dispatch(onChangeIsActive({ ids: items.map(({ id }) => String(id)), isActive: true }))}
          Icon={<CheckCircleOutline />}
        />
        <ConfirmTableActionButton
          tooltip="Deactivate"
          items={items}
          confirmTitle="Deactivating items"
          confirmText="Are you sure you want to deactivate "
          viewPath={viewPath}
          onClick={() => dispatch(onChangeIsActive({ ids: items.map(({ id }) => String(id)), isActive: false }))}
          Icon={<Block />}
        />
        <ConfirmTableActionButton
          tooltip="Delete"
          items={items}
          confirmTitle="Delete items"
          confirmText="Are you sure you want to delete "
          viewPath={viewPath}
          onClick={() => dispatch(onDelete({ ids: items.map(({ id }) => id) }))}
          Icon={<Delete />}
        />
      </TableActionGroup>
    </TableActions>
  );
}

TablesActions.defaultProps = {
  viewPath: "empty",
};

export default TablesActions;
