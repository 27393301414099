import { request as RequestService } from "@fas/ui-framework";
import environment from "environment";
import { userService } from "../user";

const getAuthorizationHeaders: () => { [key: string]: string } = () => ({
  "X-Api-Key": userService.getApiKey() || "",
});
const requestService = new RequestService({
  withCredentials: true,
  timeout: 30000,
  customAttr: {
    getAuthorizationHeaders,
    redirect: () => {
      window.location.href = `${environment.endpoints.get.loginUrl}?r=${window.location.href}`;
    },
  },
});

export default requestService;
