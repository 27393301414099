import Joi from "joi";
import joiResolver from "../../services/form/joiResolver";

const schema = Joi.object({
  id: Joi.number().allow(""),
  isActive: Joi.boolean(),
  isIncludeSubrateInfo: Joi.boolean(),
  affId: Joi.object({
    label: Joi.string(),
    value: Joi.string(),
  }).messages({ "string.empty": "Affiliate name is required and must be real Affiliate name" }),
  cpaCampaignId: Joi.object({
    label: Joi.string(),
    value: Joi.string().min(1),
  }).messages({ "string.empty": "CPA Offer is required and must be active CPA Offer" }),
  country: Joi.string().allow(null, "").messages({
    "string.empty": "Country is invalid",
  }),
  utmContent: Joi.string().allow(""),
  s3: Joi.string().allow(""),
  adjustments: Joi.array().min(1).items(Joi.object({
    id: Joi.any(),
    date: Joi.date(),
    payoutAmount: Joi.number().optional(),
    payoutAmountFirst: Joi.number().optional(),
    payoutAmountRepeats: Joi.number().optional(),
    shaveAmount: Joi.number().allow(null, "").optional(),
    increasedCommissionAmount: Joi.number().optional(),
    increasedCommissionThreshold: Joi.number().optional(),
  }).min(3).messages({
    "object.min": "Add minimum one subrate option",
  })),
}).messages({
  "number.unsafe": "This field must be a safe number",
  "number.base": "This field is required",
  "string.empty": "This field is required",
  "date.min": "Date should not be before minimal date",
  "date.max": "Date should not be after maximal date",
  "date.base": "This field is required",
  "object.base": "This field is required",
});

export default joiResolver(schema);
