import React, {
  useCallback, useEffect, useState,
} from "react";
import { FormControl, TextField } from "@mui/material";
import debounce from "lodash.debounce";
import { withFormField } from "@fas/ui-core";

export type Props = {
  name: string,
  label: string,
  error: string,
  value: number | string | null,
  onChange: (p: number | null) => void,
  disabled?: boolean,
  multiline?: boolean,
  minRows?: number,
  maxRows?: number,
  type?: string,
  inputProps?: object,
  InputProps?: object,
}

function NumberTextField({// todo move to ui-core
  name, label,
  value,
  error,
  onChange,
  ...props
}: Props) {
  const debouncedChange = useCallback(debounce(onChange, 600), [name]);
  const [localValue, setLocalValue] = useState(value);
  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  return (
    <FormControl fullWidth>
      <TextField
        data-testid={name}
        fullWidth
        label={label}
        variant="outlined"
        type="number"
        margin="dense"
        size="small"
        value={localValue === null ? "" : localValue}
        onChange={(e) => {
          const newValue: number | null = e.target.value ? Number(e.target.value) : null;
          debouncedChange(newValue);
          setLocalValue(newValue);
        }}
        onBlur={(e) => {
          const newValue: number | null = e.target.value ? Number(e.target.value) : null;
          if (newValue !== value) {
            debouncedChange.cancel();
            onChange(newValue);
          }
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter" && localValue !== value) {
            debouncedChange.cancel();
            onChange(Number(localValue));
          }
        }}
        error={Boolean(error)}
        helperText={error}
        {...props}
      />
    </FormControl>
  );
}

NumberTextField.defaultProps = {
  onChange: () => null,
  error: "",
  value: null,
};

export default withFormField(NumberTextField);
