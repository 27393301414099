import {Box} from "@mui/material";
import {SimpleSelect} from "@fas/ui-core";

// eslint-disable-next-line import/prefer-default-export
export const StatusSearchComponent = ({onChange, value = ""}: {
  onChange: (val: any) => void,
  value: string
}) => {
  const handleChange = (val: { label: string, value: string }[]) => {
    const newVal = val.length ? val[0].value : "";
    onChange(newVal);
  };
  return (
    <Box sx={{minWidth: "125px"}}>
      <SimpleSelect
        onChange={handleChange}
        selectedValues={[{value}]}
        values={[
          {label: "All", value: ""},
          {label: "Success", value: "success"},
          {label: "Fail", value: "fail"},
          {label: "Incomplete", value: "incomplete"},
        ]}
      />
    </Box>
  );
};
