// @flow
import { call, put, select, debounce } from "redux-saga/effects";
import { GET_TABLE_DATA_SAGA} from "@fas/ui-framework/lib/redux/constants";
import {
  addNotification,
  setLoading,
  setTableData,
  getTableFilters,
  getTableSorting,
  getTablePageSize,
  getTablePage,
  changeTableItemsTotalAmount,
} from "@fas/ui-framework";

import type {AxiosError} from "axios";
import {apiMapGetTableData} from "../../services/table";
import {GET_TABLE_DATA_LOADING} from "../../helpers/constants";

export type GetTableDataSaga = {
  type: typeof GET_TABLE_DATA_SAGA,
  table: keyof typeof apiMapGetTableData
}

export function* makeFetch<TData>(action: GetTableDataSaga) {
  const { table } = action;
  const filters: { [key: string]: string | number | Array<string> } = yield select(getTableFilters, table);
  const sorting: { [key: string]: "asc" | "desc" } = yield select(getTableSorting, table);
  const limit: number = yield select(getTablePageSize, table);
  const page: number = yield select(getTablePage, table);

  try {
    yield put(setLoading(GET_TABLE_DATA_LOADING, true));
    const {
      data,
      count,
    }: { data: TData, count: number } = yield call<(params: any) => any>(apiMapGetTableData[table], {
      limit,
      page,
      filters,
      sorting,
    });
    yield put(setTableData(table, data));
    yield put(changeTableItemsTotalAmount(table, count));
  }
  catch (error) {
    const err = error as {errorMessage?: string} & AxiosError;
    yield put(addNotification({ message: err.errorMessage || "Failed to fetch", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(err);
  }
  finally {
    yield put(setLoading(GET_TABLE_DATA_LOADING, false));
  }
}

export default function* watchGetTableDataSaga() {
  yield debounce(1000, GET_TABLE_DATA_SAGA, makeFetch);
}
